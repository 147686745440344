@import 'root.css';
@import 'inline.css';
$whiteColor: var(--whiteColor);
$blackColor: var(--blackColor);
$rootColor: var(--rootColor);
$primaryColor: var(--primaryColor);

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: imprima;
    user-select: none;
}

@font-face {
    font-family: imprima;
    src: url('../fonts/Imprima-Regular.ttf');
}

@mixin base($w, $h, $b, $r) {
    width: $w;
    height: $h;
    background-color: $b;
    border-radius: $r;
}

body {
    display: grid;
    place-items: center;
    background-color: $whiteColor;
}

#root {
    width: 100vw;
    height: 100svh;
    display: grid;
    place-items: center;
    background-color: $whiteColor;
}

.ghazaserver {
    @include base(100%, 100svh, $rootColor, 0);
    border: 1px solid $rootColor;
    border-top: none;
    border-bottom: none;

    @media (max-width:500px) {
        border: none;
    }

    max-width: 30rem;

    header {
        @include base(100%, 5svh, $whiteColor, 0);
        padding: 0 10px;
        border-bottom: 1px solid $rootColor;

        .logo {
            a {
                text-decoration: none;
                color: $blackColor;
            }
        }

        .header-nav {
            gap: 14px;

            svg {
                fill: none;
                width: 14px;
                height: 14px;
                stroke: $blackColor;
            }

            a {
                margin-top: 4px;
            }

            .theme-toggler {
                height: 14px;
                width: 14px;
                cursor: pointer;

                svg {
                    transition: .2s cubic-bezier(0.165, 0.84, 0.44, 1);
                    position: absolute;
                }

                &[aria-label=light] {
                    svg {
                        &:nth-child(2) {
                            scale: 0;
                            rotate: 180deg;
                        }
                    }
                }

                &[aria-label=dark] {
                    svg {
                        &:nth-child(1) {
                            scale: 0;
                            rotate: 180deg;
                        }
                    }
                }
            }
        }
    }

    nav {
        @include base(100%, 4svh, $whiteColor, 0);
        border-top: 1px solid $rootColor;

        ul {
            list-style: none;

            li {
                a {
                    width: 100%;
                    height: 100%;
                    display: grid;
                    place-items: center;

                    svg {
                        height: 16px;
                        width: 16px;
                        fill: none;
                        opacity: 40%;
                        stroke: $blackColor;

                        &[aria-checked="true"] {
                            opacity: 100%;
                        }
                    }
                }
            }
        }
    }
}

main {
    @include base(100%, calc(91svh), transparent, 0);

    .error-404 {
        gap: 14px;

        svg {
            height: 70px;
            width: 70px;
            fill: none;
            stroke: $blackColor;
        }

        p {
            font-size: 18px;
            color: $blackColor;
        }

        a {
            text-decoration: none;
        }

        button {
            transition: .1s ease;
            border: 1px solid $blackColor;
            color: $blackColor;
            background-color: transparent;
            outline: none;
            padding: 6px 18px;
            border-radius: 40px;
            cursor: pointer;

            &:hover {
                background-color: $blackColor;
                color: $whiteColor;
            }
        }
    }

    .home-feed {
        overflow-y: scroll;
        gap: 1px;

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }
}

.algo-post {
    padding: 10px;
    background-color: $whiteColor;
    gap: 3px;
    position: relative;

    .post-header {
        gap: 10px;

        .user-det {
            gap: 8px;

            img {
                @include base(30px, 30px, $rootColor , 100px);
                cursor: pointer;
            }

            .col-align {
                gap: 2px;

                p {
                    color: $primaryColor;
                    font-size: 10px;
                    cursor: pointer;
                }

                h2 {
                    color: $blackColor;
                    font-size: 12px;
                    font-weight: 100;
                    cursor: pointer;
                }
            }
        }

        svg {
            height: 16px;
            width: 16px;
            fill: none;
            stroke: $blackColor;
            cursor: pointer;
        }

        .-header-actions {
            position: absolute;
            right: 10px;
            top: 40px;
            background-color: $whiteColor;
            border-radius: 5px;
            padding: 10px;
            border: 1px solid $rootColor;
            gap: 2px;
            z-index: 3;

            &[aria-checked="false"] {
                display: none;
            }

            p {
                font-size: 10px;
                gap: 4px;
                color: $blackColor;
                cursor: pointer;
                padding: 5px;

                &:hover {
                    background-color: $rootColor;
                    border-radius: inherit;
                }

                svg {
                    height: 12px;
                    width: 12px;
                    stroke: $blackColor;
                }

                .stroke-width {
                    stroke-width: 1.5;
                }
            }
        }
    }

    .post-caption {
        margin-left: 2.38rem;
        word-wrap: break-word;
        word-break: break-all;
        margin-bottom: 6px;
        color: $blackColor;
        font-weight: 100;
        font-size: 14px;
    }

    .post-content {
        gap: 16px;
        margin-left: 4px;
        width: calc(100% - 6px);

        .post-date {
            writing-mode: vertical-rl;
            rotate: 180deg;
            color: $blackColor;
            font-weight: 100;
            font-size: 14px;
        }

        .post-fetch {
            @include base(100%, 100%, transparent, 0);
            min-height: 300px;
            position: relative;

            img,
            video {
                @include base(100%, 100%, transparent, 5px);
            }

            .volume-toggle {
                position: absolute;
                bottom: 10px;
                right: 10px;
                cursor: pointer;
                @include base(30px, 30px, rgba(0, 0, 0, 0.07), 100px);

                svg {
                    width: 10px;
                    height: 10px;
                    stroke: white;
                    fill: none;
                }

                .volume {
                    transition: .2s ease;
                    @include base(10px, 10px, transparent, 100px);
                    border: 1px solid transparent;
                    border-right: 1px solid white;
                    margin-left: -7px;
                }

                &[aria-disabled="true"] {
                    .volume {
                        position: absolute;
                        @include base(2px, 18px, white, 100px);
                        border: .5px solid rgba(0, 0, 0, 0.84);
                        rotate: -45deg;
                        top: 6px;
                        left: 22px;
                    }
                }
            }
        }
    }

    .post-actions {
        grid-template-columns: repeat(5, auto);
        height: 30px;
        gap: 2px;

        .action-def {
            cursor: pointer;
            gap: 4px;

            &:nth-child(1) {
                margin-left: 20px;
            }

            svg {
                transition: .2s ease;
                height: 16px;
                width: 16px;
                fill: none;
                stroke: $blackColor;
            }

            p {
                color: $blackColor;
                font-size: 10px;
            }

            &[aria-checked="true"] {
                svg {
                    fill: $primaryColor;
                    stroke: $whiteColor;
                    scale: 1.2;
                }

                p {
                    color: $primaryColor;
                }
            }
        }
    }
}

.getting-posts {
    min-height: 60px;

    .loader {
        @include base(30px, 30px, transparent, 100px);
        border: 1px solid $whiteColor;
        border-bottom: 1px solid transparent;
        animation: run .6s linear infinite;
    }

    @keyframes run {
        100% {
            rotate: 360deg;
        }
    }

    &[aria-disabled="true"] {
        display: none;

        .loader {
            animation-play-state: paused;
        }
    }
}

.blur {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100vw;
    height: 100svh;

    &[aria-checked="false"] {
        display: none;
    }
}