.df {
    display: flex;
}

.js {
    justify-content: flex-start;
}

.jc {
    justify-content: center;
}

.je {
    justify-content: flex-end;
}

.ja {
    justify-content: space-around;
}

.jb {
    justify-content: space-between;
}

.jv {
    justify-content: space-evenly;
}

.as {
    align-items: flex-start;
}

.ac {
    align-items: center;
}

.ae {
    align-items: flex-end;
}

.rr {
    flex-direction: row-reverse;
}

.fc {
    flex-direction: column;
}

.fr {
    flex-direction: column-reverse;
}

.dg {
    display: grid;
}

.oc {
    object-fit: cover;
}

.wf {
    width: 100%;
}

.hf {
    height: 100%;
}